(function($) {
$(document).ready(function(){
	// HTML markup implementation, overlap mode, initilaize collapsed
	$('#menu').multilevelpushmenu({
		containersToPush: [$( '#pushobj' )],
		direction: 'rtl',
		mode: 'overlap', //cover
		collapsed: true,
		fullCollapse: true,
		menuWidth: '280px',
		menuHeight: '100%',
		swipe: 'touchscreen', //Swipe gestures disbaled re: Issue: 75 - https://github.com/adgsm/multi-level-push-menu/issues/75
  
		// Add menu Styling
		wrapperClass: 'mlpm_w',
		menuInactiveClass: 'mlpm_inactive',
		menuActiveClass: 'mlpm_active',
		backItemIcon: 'fa fa-angle-left',
		groupIcon: 'fa fa-angle-right', 
    
		onItemClick: function() {
			// First argument is original event object
			var event = arguments[0],
			// Second argument is menu level object containing clicked item (<div> element)
			$menuLevelHolder = arguments[1],
			// Third argument is clicked item (<li> element)
			$item = arguments[2],
			// Fourth argument is instance settings/options object
			options = arguments[3];
			// Anchor href
			var itemHref = $item.find( 'a:first' ).attr( 'href' );
    		// Redirecting the page
    		location.href = itemHref;
   		},
		
   		onMenuReady: function() {
    		$('#menu').show();
   		},
		
		/*
		onExpandMenuStart: function(options) {
			console.log(options);
			var level = $('#menu').multilevelpushmenu('activemenu').data('level');
			console.log('expanding start.. level=' + level);
			if (level === null) { }
   		},
		*/
		
		onCollapseMenuStart: function(options) {
			var level = $('#menu').multilevelpushmenu('activemenu').data('level');
    		//console.log('collapsing start.. level=' + level);
    		if (level === 0) {
     			//console.log('inactive');
     			$('#pushobj').removeClass('opened');
    		}
   		}
    
	});
  
	$('#menu').multilevelpushmenu('redraw');
	
	function mlpmToggle() {
        var menuExpanded = $('#menu').multilevelpushmenu('menuexpanded',$('#menu').multilevelpushmenu('findmenusbytitle', 'Menu').first());
		console.log(menuExpanded);
        if(menuExpanded){
            $('#menu').multilevelpushmenu('collapse');
        }
        else {
            $('#menu').multilevelpushmenu('expand');
        }
	}
	/* Menu Btn Toggle */
	$('#mlpm_btn').click(function(){ 
		mlpmToggle();
	});
	$('#menu #menu_multilevelpushmenu .levelHolderClass > h2').click(function(){ 
		mlpmToggle();
	});
	
	/* Full Expand
	$('.mlpm_btn').click(function(){
		$('#menu').multilevelpushmenu('expand');
		$('#pushobj').addClass('opened');
	});
	
	// Full Collapse
	$('#menu h2').click(function() {
		$('#menu').multilevelpushmenu('collapse');
		$('#pushobj').removeClass('opened');
	});
	*/
			
								
});
	
$(window).resize(function () {
	$('#menu').multilevelpushmenu('option', 'menuHeight', $(document).height());
	$('#menu').multilevelpushmenu('redraw');
});

})(jQuery); // Fully reference jQuery after this point.